import { useState } from "react";
import LoginForm from "../../components/LoginForm";
import RegisterForm from "../../components/RegisterForm";

const AuthPage = () => {
  const [isRegister, setIsRegister] = useState(false);

  const handleSwitchAuthMode = () => {
    setIsRegister((prev) => !prev);
  };

  return (
    <div className="text-center">
      <h1 className="text-3xl">{isRegister ? "Register" : "Login"}</h1>
      <div
        onClick={handleSwitchAuthMode}
        className="cursor-pointer text-cyan-800 my-3"
      >
        Let's {isRegister ? "Login" : "Register"}
      </div>

      {isRegister ? <RegisterForm /> : <LoginForm />}
    </div>
  );
};

export default AuthPage;
