import { createContext, useReducer } from "react";
import assignmentService from "../services/assignment.service";
import PropTypes from "prop-types";

const initialAppState = {
  assignments: [],
  total: 0,
};

export const replaceItemInArrayByKey = (array, key, newItem) => {
  const index = array.findIndex((item) => item[key] === newItem[key]);
  if (index !== -1) {
    const newArray = [...array];
    newArray[index] = newItem;
    return newArray;
  }
  return array;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ASSIGNMENTS": {
      const { assignments } = action.payload;
      return { ...state, assignments };
    }
    case "CREATE_ASSIGNMENTS": {
      const { assignment } = action.payload;
      return {
        ...state,
        assignments: [assignment].concat(state.assignments),
        total: state.total + 1,
      };
    }
    case "UPDATE_ASSIGNMENTS": {
      const { assignment } = action.payload;
      const updatedAssignments = replaceItemInArrayByKey(
        state.assignments,
        "id",
        assignment
      );
      return { ...state, assignments: updatedAssignments };
    }
    case "DELETE_ASSIGNMENTS": {
      const { id } = action.payload;
      return {
        ...state,
        assignments: state.assignments.filter(
          (assignment) => assignment.id !== id
        ),
        total: state.total - 1,
      };
    }

    default:
      return { ...state };
  }
};

const AssignmentContext = createContext({
  ...initialAppState,
  get: () => {},
  create: () => {},
  update: () => {},
  destroy: () => {},
});

export const AssignmentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAppState);

  const get = async (params) => {
    try {
      const data = await assignmentService.get(params);
      dispatch({
        type: "SET_ASSIGNMENTS",
        payload: {
          assignments: data,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const create = async (data) => {
    try {
      const assignment = await assignmentService.create(data);
      dispatch({
        type: "CREATE_ASSIGNMENTS",
        payload: {
          assignment,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const update = async (id, data) => {
    try {
      const assignment = await assignmentService.update(id, data);
      dispatch({
        type: "UPDATE_ASSIGNMENTS",
        payload: {
          assignment,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const destroy = async (id) => {
    try {
      await assignmentService.delete(id);
      dispatch({
        type: "DELETE_ASSIGNMENTS",
        payload: {
          id,
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <AssignmentContext.Provider
      value={{ ...state, method: "Assignment", get, update, create, destroy }}
    >
      {children}
    </AssignmentContext.Provider>
  );
};

AssignmentProvider.propTypes = {
  children: PropTypes.node,
};

export default AssignmentContext;
