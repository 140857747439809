import axiosInstance from "../config/axios";

const baseUrl = "/authentication";

class AuthService {
  constructor() {
    this.axios = axiosInstance;
  }

  async register(data) {
    try {
      const res = await this.axios.post(`${baseUrl}/register`, data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }

  async login(data) {
    try {
      const res = await this.axios.post(`${baseUrl}/login`, data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getCurrentUser() {
    try {
      const res = await this.axios.get(`${baseUrl}/info`);
      return res.data;
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }
}

export default new AuthService();
