import useAssignment from "../api/useAssignment";
import useAuth from "../api/useAuth";
import { useState } from "react";

const Header = () => {
  const { user, logout } = useAuth();
  const [note, setNote] = useState("");
  const { create: createAssignment } = useAssignment();

  const handleAddAssignment = (e) => {
    e.preventDefault();
    createAssignment({ description: note });
  };

  return (
    <>
      <div className="flex gap-4 justify-end px-4">
        <div>Xin chào, {user?.username}</div>
        <button onClick={logout} className="text-red-600 cursor-pointer">
          Logout
        </button>
      </div>
      <form className="max-w-sm mx-auto">
        <label
          htmlFor="message"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Your note
        </label>
        <textarea
          id="message"
          rows="4"
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Leave a comment..."
          value={note}
          onChange={(e) => setNote(e.target.value)}
        ></textarea>
        <button
          type="submit"
          className="block w-full px-4 py-2 mt-4 text-sm font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
          onClick={handleAddAssignment}
        >
          Add
        </button>
      </form>
    </>
  );
};

export default Header;
