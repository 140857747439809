import AuthGuard from "../hoc/Guard/AuthGuard";
import AuthPage from "../pages/AuthPage";
import { useRoutes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import GuestGuard from "../hoc/Guard/GuestGuard";

export default function Router() {
  const routes = [
    {
      path: "/",
      element: (
        <AuthGuard>
          <Dashboard />
        </AuthGuard>
      ),
    },
    {
      path: "auth",
      element: (
        <GuestGuard>
          <AuthPage />,
        </GuestGuard>
      ),
    },
  ];

  return useRoutes(routes);
}
