import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./routers/routes.js";
import { AuthProvider } from "./contexts/AuthContext.jsx";
import { AssignmentProvider } from "./contexts/AssignmentContext.jsx";

function App() {
  return (
    <div className="App ">
      <AuthProvider>
        <AssignmentProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AssignmentProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
