import Header from "../components/Header";
import Kanban from "../Kanban";

const Dashboard = () => {
  return (
    <>
      <Header />
      <Kanban />
    </>
  );
};

export default Dashboard;
