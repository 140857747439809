import { createContext, useEffect, useReducer } from "react";
// import jwtDecode from "jwt-decode";
import authService from "../services/auth.service";
import PropTypes from "prop-types";

const initialAppState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = (accessToken) => {
  return accessToken;
  // if (!accessToken) {
  //   return false;
  // }

  // const decoded = jwtDecode(accessToken);
  // const currentTime = Date.now() / 1000;

  // return !decoded.exp || decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("access_token", accessToken);
  } else {
    localStorage.removeItem("access_token");
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "SET_LANGUAGE":
      return { ...state, language: action.payload };
    case "LOGIN": {
      const { user, isAuthenticated } = action.payload;
      return { ...state, user, isAuthenticated };
    }
    case "register": {
      const { user, isAuthenticated } = action.payload;
      return { ...state, user, isAuthenticated };
    }
    case "LOGOUT": {
      return { ...state, user: null, isAuthenticated: false };
    }

    default:
      return { ...state };
  }
};

const AuthContext = createContext({
  ...initialAppState,
  setLanguage: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAppState);

  const register = async (data) => {
    try {
      const response = await authService.register(data);

      const { token } = response;

      if (isValidToken(token)) {
        setSession(token);
      }

      // dispatch({
      //   type: "LOGIN",
      //   payload: {
      //     isAuthenticated: true,
      //   },
      // });
    } catch (error) {
      dispatch({
        type: "LOGIN",
        payload: {
          user: null,
          isAuthenticated: false,
        },
      });

      // throw new Error(error.message);
    }
  };

  const login = async (data) => {
    try {
      const response = await authService.login(data);

      const { token } = response;

      if (isValidToken(token)) {
        setSession(token);
      }
      const user = await authService.getCurrentUser();

      dispatch({
        type: "LOGIN",
        payload: {
          isAuthenticated: true,
          user,
        },
      });
    } catch (error) {
      dispatch({
        type: "LOGIN",
        payload: {
          user: null,
          isAuthenticated: false,
        },
      });

      // throw new Error(error.message);
    }
  };

  const logout = () => {
    setSession(null);
    dispatch({
      type: "LOGOUT",
    });
  };

  const initialise = async () => {
    try {
      const accessToken = window.localStorage.getItem("access_token");

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const data = await authService.getCurrentUser();

        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: true,
            user: data,
          },
        });
      } else {
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            language: "en",
            user: null,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    initialise();

    return () => {};
  }, []);

  if (!state.isInitialised) {
    return <div>huhu from AuthContext</div>;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "App",
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthContext;
