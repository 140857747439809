import axiosInstance from "../config/axios";

const baseUrl = "/assignment";

class AssignmentService {
  constructor() {
    this.axios = axiosInstance;
  }

  async get() {
    try {
      const res = await this.axios.get(`${baseUrl}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }

  async create(data) {
    try {
      const res = await this.axios.post(`${baseUrl}`, data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }

  async update(id, data) {
    try {
      const res = await this.axios.put(`${baseUrl}/${id}`, data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }

  async destroy(id) {
    try {
      const res = await this.axios.delete(`${baseUrl}/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new AssignmentService();
